import React from 'react';

import type { Icon as IconType } from '@rover/icons';
import { Box, Flex, Text } from '@rover/kibble/core';
import { SpacingSystemType, StringSystemType } from '@rover/kibble/core/styledSystemPropTypes';
import { A11yHidden, ThemeFontWeight, ThemeTextSizes } from '@rover/kibble/styles';

import {
  ICON_COLOR_FOR_VARIANT,
  IconBlockVariants,
  TITLE_COLOR_FOR_VARIANT,
} from './IconBlock.common';

export type Props = {
  title: string;
  ariaLabel?: string;
  icon: IconType;
  variant?: IconBlockVariants;
  children?: React.ReactNode;
  size?: ThemeTextSizes;
  iconSize?: string;
  fontWeight?: ThemeFontWeight;
  iconMargin?: SpacingSystemType;
  overrideTitleTopMargin?: SpacingSystemType;
  titleBoxFlex?: StringSystemType;
  iconColor?: string;
};

export const IconBlock = ({
  title,
  ariaLabel,
  icon: Icon,
  variant = 'default',
  size = '300',
  iconSize = '32px',
  fontWeight = 'semibold',
  iconMargin = '3x',
  overrideTitleTopMargin,
  children,
  titleBoxFlex = '1',
  iconColor = ICON_COLOR_FOR_VARIANT[variant].toString(),
}: Props): JSX.Element => {
  const titleColor = TITLE_COLOR_FOR_VARIANT[variant];
  const topMargin = overrideTitleTopMargin || (size === '300' ? '1x' : '0x');

  return (
    <Flex flexDirection="row" alignItems="center">
      <Flex marginRight={iconMargin} aria-hidden>
        <Icon height={iconSize} width={iconSize} fill={iconColor} size={size} />
      </Flex>
      <Box flex={titleBoxFlex} mt={topMargin}>
        {!!ariaLabel && <Text sx={{ A11yHidden }}>{ariaLabel}</Text>}
        <Text fontWeight={fontWeight} textColor={titleColor} size={size} aria-hidden={!!ariaLabel}>
          {title}
        </Text>
        <Box aria-hidden={!!ariaLabel}>{children}</Box>
      </Box>
    </Flex>
  );
};

export default IconBlock;
