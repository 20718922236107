/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import ReactModal from 'react-modal';

import { Box, Flex } from '@rover/kibble/core';
import { ZIndex } from '@rover/kibble/styles';
import Elevator from '@rover/react-lib/src/components/utils/Elevator';

import Content from './Content/Content';
import { ModalCommonProps, usePreventScroll, useReactModalSetup } from './Modal.common';
import Overlay from './Overlay';

export type Props = ModalCommonProps & {
  aria?: Record<string, string>;
  appElementSelector?: string;
  headerId?: string;
  modalRef?: (instance: HTMLElement) => void;
};

export default function Modal({
  children,
  isOpen,
  onRequestClose,
  headerText,
  headerId,
  headerSize,
  showCloseIcon,
  appElementSelector = '#base-content',
  contentProps = {},
  overlaySx = {},
  modalRef,
  ...other
}: Props): JSX.Element {
  const { staticHeaderId, modalId } = useReactModalSetup(appElementSelector);
  const actualHeaderId = headerId || staticHeaderId;
  usePreventScroll(isOpen, modalId);

  // ReactModal takes all aria-* props in an `aria` object.
  const ariaProps = other.aria || {};
  Object.keys(other).forEach((key) => {
    if (key.startsWith('aria-')) {
      ariaProps[key.substring(5)] = other[key];
      delete other[key]; // eslint-disable-line no-param-reassign
    }
  });

  return (
    <ReactModal
      aria={{ labelledby: actualHeaderId }}
      contentRef={modalRef}
      className="RoverModalContent"
      closeTimeoutMS={150}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="RoverModalOverlay"
      overlayElement={(props, overlayChildren): JSX.Element => (
        <Overlay data-testid="modal-overlay" {...props} sx={overlaySx}>
          {overlayChildren}
        </Overlay>
      )}
    >
      <Box {...ariaProps}>
        <Elevator elevation={4}>
          <Flex
            background="primary"
            borderRadius="primary"
            height="auto"
            maxWidth="600px"
            minHeight="100px"
            maxHeight="100vh"
            padding="6x"
            width="auto"
            zIndex={ZIndex.MODAL.toString()}
            {...contentProps}
          >
            <Content
              headerId={actualHeaderId}
              headerText={headerText}
              headerSize={headerSize}
              onRequestClose={onRequestClose}
              showCloseIcon={showCloseIcon}
              {...other}
            >
              {children}
            </Content>
          </Flex>
        </Elevator>
      </Box>
    </ReactModal>
  );
}
