import { Color, DSTokenMap, ThemeTextColor } from '@rover/kibble/styles';

export enum IconBlockVariant {
  DEFAULT = 'default',
  SUCCESS = 'success',
  CAUTION = 'caution',
  ERROR = 'error',
}

// from https://bobbyhadz.com/blog/typescript-convert-enum-to-union
export type IconBlockVariants = `${IconBlockVariant}`;

export const TITLE_COLOR_FOR_VARIANT: Record<IconBlockVariant, ThemeTextColor> = {
  [IconBlockVariant.DEFAULT]: 'primary',
  [IconBlockVariant.SUCCESS]: 'success',
  [IconBlockVariant.CAUTION]: 'caution',
  [IconBlockVariant.ERROR]: 'error',
};

export const ICON_COLOR_FOR_VARIANT: Record<IconBlockVariant, Color> = {
  [IconBlockVariant.DEFAULT]: DSTokenMap.TEXT_COLOR_PRIMARY,
  [IconBlockVariant.SUCCESS]: DSTokenMap.TEXT_COLOR_SUCCESS,
  [IconBlockVariant.CAUTION]: DSTokenMap.TEXT_COLOR_CAUTION,
  [IconBlockVariant.ERROR]: DSTokenMap.TEXT_COLOR_ERROR,
};
