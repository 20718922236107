import React from 'react';

import { Close } from '@rover/icons';
import { Box, Button, Flex, Heading } from '@rover/kibble/core';

import type { DialogButtonProps, ModalCommonProps } from '../Modal.common';

import DialogButtons from './DialogButtons';

export type Props = DialogButtonProps &
  Omit<ModalCommonProps, 'isOpen'> & {
    headerId?: string;
  };

export default function ModalContent({
  children,
  showCloseIcon,
  headerText,
  headerId,
  headerSize = '300',
  onRequestClose,
  ...buttonProps
}: Props): JSX.Element {
  return (
    <Flex flexDirection="column" width="100%">
      <Flex
        flexDirection="row"
        justifyContent={headerText ? 'space-between' : 'flex-end'}
        alignItems={headerText ? 'flex-start' : 'flex-end'}
      >
        {headerText && (
          <Box mb="4x">
            <Heading id={headerId} size={headerSize} m="0x">
              {headerText}
            </Heading>
          </Box>
        )}
        {showCloseIcon && (
          <Box ml="4x">
            <Button
              icon={Close}
              onClick={(): void => onRequestClose()}
              data-qa-id="modal-dismiss-button"
              data-testid="modal-dismiss-button"
              aria-label="Dismiss modal"
              sx={{ boxShadow: 'none', marginTop: '-12px', marginRight: '-12px' }}
              circular
            />
          </Box>
        )}
      </Flex>
      <Box flex="1" flexBasis="auto" pt="0x">
        {children}
      </Box>
      <DialogButtons {...buttonProps} />
    </Flex>
  );
}
