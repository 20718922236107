import React from 'react';

import { Button, Flex } from '@rover/kibble/core';

import type { DialogButtonProps } from '../Modal.common';

export default function DialogButtons({
  buttonDirection = 'column',
  cancelButtonText,
  onClickCancel,
  onClickSubmit,
  submitButtonText,
  submitButtonDisabled = false,
  submitButtonLoading = false,
}: DialogButtonProps): JSX.Element {
  // Since we have limited width on native apps, we are essentially transforming row into column and row-reverse into column-reverse.
  // This helps prevent issues with long text on buttons.
  if (process.env.JS_ENV_NATIVE && buttonDirection.includes('row')) {
    // eslint-disable-next-line no-param-reassign
    buttonDirection = buttonDirection.replace('row', 'column') as 'column' | 'column-reverse';
  }

  const hasButtons = (submitButtonText && onClickSubmit) || (cancelButtonText && onClickCancel);
  if (!hasButtons) return <Flex flex="1" />;
  return (
    <Flex flexDirection={buttonDirection} justifyContent="center" mt="8x" gap="4x">
      {submitButtonText && onClickSubmit && (
        <Button
          disabled={submitButtonDisabled}
          fullWidth
          loading={submitButtonLoading}
          onClick={onClickSubmit}
          variant="primary"
        >
          {submitButtonText}
        </Button>
      )}
      {cancelButtonText && onClickCancel && (
        <Button variant="flat" fullWidth onClick={onClickCancel}>
          {cancelButtonText}
        </Button>
      )}
    </Flex>
  );
}
