import React from 'react';
import styled from 'styled-components';

import type { ElevationLevels } from '@rover/kibble/styles';
import { Elevation } from '@rover/kibble/styles';

export type Props = {
  elevation: ElevationLevels;
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

// eslint-disable-next-line rover/prefer-kibble-components
const ElevatedDiv = styled.div<{
  elevation: ElevationLevels;
}>`
  ${({ elevation }) => Elevation(elevation)};
`;

const Elevator = ({ elevation = 1, ...props }: Props): JSX.Element => (
  <ElevatedDiv elevation={elevation} {...props} />
);

export default Elevator;
